const dashboard = [
    {
        path: "/admin/dashboard",
        name: "admin-dashboard",
        meta: {
            title: "Admin dashboard",
            authRequired: true,
        },
        component: () => import("../pages/admin/Home.vue"),
    },
]

const products = [
    {
        path: "/admin/products",
        name: "admin-products",
        meta: {
            title: "Produits",
            authRequired: true,
        },
        component: () => import("../pages/admin/products/Products.vue"),
    },
    {
        path: "/admin/products/:id",
        name: "admin-product",
        meta: {
            title: "Produit",
            authRequired: true,
        },
        component: () => import("../pages/admin/products/SingleProduct.vue"),
    }
]
const users = [
    {
        path: "/admin/coordinators",
        name: "admin-coordinator",
        meta: {
            title: "Coordinateurs",
        },
        component: () => import("../pages/admin/users/coordinator.vue"),
    },
    {
        path: "/admin/supervisors",
        name: "admin-supervisors",
        meta: {
            title: "Superviseurs",
        },
        component: () => import("../pages/admin/users/User.vue"),
    },
    {
        path: "/admin/commercials",
        name: "admin-commercials",
        meta: {
            title: "Commerciaux",
        },
        component: () => import("../pages/admin/users/Commercial.vue"),
    },
    {
        path: "/admin/members",
        name: "admin-members",
        meta: {
            title: "Adhérents",
        },
        component: () => import("../pages/admin/users/Member.vue"),
    },
    {
        path: "/admin/members/:id",
        name: "admin-members-single",
        meta: {
            title: "Adhérents",
        },
        component: () => import("../pages/admin/users/single-member.vue"),
    },
    {
        path: "/admin/commercials/:id",
        name: "admin-commercial-single",
        meta: {
            title: "Commerciaux",
        },
        component: () => import("../pages/admin/users/single-commercial.vue"),
    },
    {
        path: "/admin/supervisors/:id",
        name: "admin-supervisors-single",
        meta: {
            title: "Superviseurs",
        },
        component: () => import("../pages/admin/users/single-supervisor.vue"),
    },
    {
        path: "/admin/coordinators/:id",
        name: "admin-coordinator-single",
        meta: {
            title: "Coordinateurs",
        },
        component: () => import("../pages/admin/users/single-coordinator.vue"),
    },
    {
        path: "/admin/statistics/:id",
        name: "user-statistics",
        meta: {
            title: "Statistiques",
        },
        component: () => import("../pages/admin/users/Statistics.vue"),
    }
]

const transactions = [
    {
        path: "/admin/transaction/subscription",
        name: "admin-transactions-subscription",
        meta: {
            title: "Transactions de souscription",
        },
        component: () => import("../pages/admin/transactions/SubscriptionTransaction.vue"),
    },
    {
        path: "/admin/transaction/savings",
        name: "admin-transactions-contribution",
        meta: {
            title: "Transactions d'Epargne",
        },
        component: () => import("../pages/admin/transactions/ContributionTransaction.vue"),
    },
    {
        path: "/admin/transactions/failed",
        name: "admin-transactions-failed",
        meta: {
            title: "Transactions",
            authRequired: true,
        },
        component: () => import("../pages/admin/transactions/failed-transactions.vue"),
    },
    {
        path: "/admin/transaction/funding-refund",
        name: "admin-transaction-funding-refund",
        meta: {
            title: "Remboursement Financement"
        },
        component: () => import("../pages/admin/transactions/funding-refund-transaction.vue")
    }
]

const profile = [
    {
        path: "/admin/profile",
        name: "admin-profile",
        meta: {
            title: "Profile",
        },
        component: () => import("../pages/admin/profile/Index.vue"),
    }

]

const parameters = [
    {
        path: "/admin/parameter/application",
        name: "admin-application-parameter",
        meta: {
            title: "Parameter",
        },
        component: () => import("../pages/admin/parameter/Application.vue"),
    }
]

const account = [
    {
        path: "/admin/accounts",
        name: "admin-account",
        meta: {
            title: "Accounts",
        },
        component: () => import("../pages/admin/account/Account.vue"),
    },
    {
        path: "/admin/accounts/:id",
        name: "admin-account-detail",
        meta: {
            title: "Accounts",
        },
        component: () => import("../pages/admin/account/single-account.vue"),
    },

]

const funding = [
    {
        path: "/admin/funding-requests",
        name: "admin-funding-request",
        meta: {
            title: "Funding requests",
        },
        component: () => import("../pages/admin/funding/request/Request.vue"),
    },
    {
        path: "/admin/funding-granted",
        name: "admin-funding-granted",
        meta: {
            title: "Accounts",
        },
        component: () => import("../pages/admin/funding/granted/granted.vue"),
    },
    {
        path: "/admin/funding-requests/:id",
        name: "admin-funding-request-show",
        meta: {
            title: "Funding Request"
        },
        component: () => import("../pages/admin/funding/request/SingleFundingRequest.vue"),
    },
    {
        path: "/admin/funding/:id",
        name: "admin-funding-show",
        meta: {
            title: "Funding",
        },
        component: () => import("../pages/admin/funding/granted/single-granted.vue"),
    }

]

const location = [
    {
        path: "/admin/towns",
        name: "admin-towns",
        meta: {
            title: "Towns",
        },
        component: () => import("../pages/admin/location/town.vue"),
    },
]

const notification = [
    {
        path: "/admin/notifications",
        name: "admin-notification",
        meta: {
            title: "Notification",
        },
        component: () => import("../pages/admin/notification/index.vue"),
    },
]

const search = [
    {
        path: "/admin/search",
        name: "admin-search",
        meta: {
            title: "Recherche",
        },
        component: () => import("../pages/admin/search/index.vue"),
    },
]

const adminRoutes = [
    ...dashboard,
    ...users,
    ...transactions,
    ...profile,
    ...parameters,
    ...account,
    ...products,
    ...funding,
    ...location,
    ...notification,
    ...search
]

adminRoutes.forEach(route => {
    route.meta.adminRequired = true;
    route.meta.authRequired = true;
})

export default [
    ...adminRoutes
]